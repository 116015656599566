<!-- eslint-disable -->
<template>
    <div class="flex items-center justify-center" style="background-image: url(); background-size: cover; height: 100vh">
        <Alert />
        <div class="w-50-l w-90">
            <div class="tc pb4">
                <img src="../../assets/images/simplebks-logo.png" style="width: 180px" alt="simplebks-logo" />
            </div>

            <div class="pa2" style="background-color: white; border-radius: 5px;">
                <div class="tc pt4 pb2">
                    <img src="../../assets/images/credit-card-new-payment-successful-svgrepo-com.svg" width="100px" alt="payment icon" />
                </div>
                <div class="tc pb3">
                    <h2 class="lh-copy">Your payment for the invoice has been processed</h2>
                    <div class="lh-copy">This invoice was issued through Simplebks. Accounting Made Easy! 💛 For Small Businesses.</div>
                </div>

                    <div id="form" class="tc mt3 mb5">
                        Not heard of Simplebks? <a href="https://simplebks.com" class="b">Get started</a>.
                    </div>
            </div>
        </div>
    </div>
</template>
<!-- eslint-disable -->
<script>
import { onMounted } from 'vue'

export default {
    name: 'PaystackPageConfirmation',

    setup() {
        
        onMounted(() => {})

        return { }
    }
}
</script>